<template>
  <div class="elearning-teacher">
    <div class="mb-6 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-md-center justify-space-between">
      <span class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        E-Learning
        <span class="d-block text-subtitle-2 mt-1 font-weight-lighter">Semua E-Learning yang diikuti</span>
      </span>
      <div class="d-flex align-center mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
      </div>
    </div>
    <v-row class="align-center">
      <v-col
        md="4"
        cols="12"
      >
        <v-text-field
          label="Cari"
          outlined
          dense
          class="white"
          hide-details="none"
        >
        </v-text-field>
      </v-col>
      <v-col md="8">
        <v-row class="justify-end">
          <v-col
            md="4"
            cols="12"
          >
            <div class="d-md-flex align-center">
              <h4>
                Kelas:
              </h4>
              <div class="ml-md-2">
                <v-combobox
                  label="Semua"
                  outlined
                  dense
                  class="white"
                  hide-details="none"
                ></v-combobox>
              </div>
            </div>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <div class="d-md-flex align-center">
              <h4>
                Urutkan:
              </h4>
              <div class="ml-md-2">
                <v-combobox
                  label="Semua"
                  outlined
                  dense
                  class="white"
                  hide-details="none"
                ></v-combobox>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="!isLoadingCard"

    >
      <v-col
        v-for="(subject,index) in subjectsFiltered"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <v-card>
          <v-img
            height="150"
            src="@/assets/images/vector/cbt.svg"
            width="100%"
          ></v-img>
          <v-card-text
            class="py-4"
          >
            <h2 class="font-weight-bold mb-2 black--text txt-ellipsis">
              {{ subject.name }}
            </h2>
            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icon.iconUser"
                  width="20"
                ></v-img>
                <span class="ml-4">{{ subject.teacher.employee.name }}</span>
              </div>
            </div>

            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icon.iconClass"
                  width="20"
                ></v-img>
                <span class="ml-4">{{ subject.class.name }}</span>
              </div>
            </div>

            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icon.iconClock"
                  width="20"
                ></v-img> <span class="ml-4">{{ getFormatDate(subject.created_at) }}</span>
              </div>
            </div>
            <div class="d-flex justify-space-between  align-center">
              <div>
                <span class="font-weight-bold">0 Orang mengikuti CBT</span>
              </div>
              <div class="v-avatar-group">
                <v-avatar
                  size="40"
                >
                  <v-img src="@/assets/images/avatars/1.png"></v-img>
                </v-avatar>
                <v-avatar
                  size="40"
                >
                  <v-img src="@/assets/images/avatars/2.png"></v-img>
                </v-avatar>
                <v-avatar
                  size="40"
                >
                  <v-img src="@/assets/images/avatars/3.png"></v-img>
                </v-avatar>
              </div>
            </div>
          </v-card-text>
          <v-card-actions
            class="pa-0"
          >
            <v-btn
              width="100%"
              color="primary"
              x-large
              class="rounded-t-0"
              :to="{ name: 'elearning-teacher-subject-section',params: {uuid : subject.uuid}}"
            >
              Lihat E-Learning
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else
    >
      <v-col
        v-for="i,index in 3"
        :key="index"
        md="4"
      >
        <v-skeleton-loader
          type="card-avatar,article, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div
      v-if="isEmpty"
      class="pa-4 d-flex justify-center"
    >
      <div
        class="pa-10"
      >
        <v-img
          src="@/assets/images/vector/empty.svg"
          class=""
        ></v-img>
        <h3 class="text-center text--black my-6">
          Belum ada mata pelajaran
        </h3>
      </div>
    </div>
    <div
      v-if="subjectsFiltered.length"
      class="mt-6"
    >
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        @change="paginationHandler"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import iconClass from '@/assets/icons/class.svg'
import iconClock from '@/assets/icons/clock.svg'
import iconUser from '@/assets/icons/user.svg'

export default {
  name: 'Subject',
  data() {
    return {
      search: '',
      filtered: [],
      subjects: [],
      isLoadingData: false,
      isLoadingCard: true,
      icon: {
        iconUser,
        iconClock,
        iconClass,
      },
      skeletonTypes: {},
      school: [],
      school_uuid: null,
      teacher_uuid: null,
      page: 1,
      totalPages: 0,
      isEmpty: false,
    }
  },
  computed: {
    subjectsFiltered() {
      return this.setSubject()
    },
  },
  watch: {
    page: {
      handler() {
        this.getSubject()
      },
    },
  },

  async mounted() {
    this.getSchoolUuid()
    await this.getSubject()
    this.isLoadingCard = false
  },
  methods: {
    setSubject() {
      this.filtered = this.subjects.filter(post => post.name.toLowerCase().includes(this.search.toLowerCase()))

      return this.filtered
    },

    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${year}-${month.slice(-2)}-${day.slice(-2)} ${time}`
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
        this.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    async getSubject(params = {}) {
      this.isLoadingCard = true

      await this.$services.ApiServices.list(this.$options.name, {
        ...params,
        page: this.page,
        per_page: 6,
        teacher_uuid: this.teacher_uuid,
      }).then(
        ({ data }) => {
          this.subjects = data.data.filter(item => item.is_active === 'Active')
          console.log('subjects', this.subjects)
          this.isEmpty = false
          if (this.subjects.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
  },
}
</script>

<style scoped>
.match-height > [class*='col'] {
  display: flex !important;
  flex-flow: column !important;
}

.match-height > [class*='col'] > .v-card {
  flex: 1 1 auto !important;
}

.btn-elearning-card {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}

.v-input::v-deep .v-input_control > .v-input_slot {
  margin: 0 !important;
}

.textFieldHidden::v-deep .v-input__control > .v-input__slot {
  margin: 0;
}
.txt-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
